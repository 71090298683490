const config = {
    initiateLoginEndpoint: "https://teams.easycallreport.dev.easyplatform.app/auth-start",
    clientId: '53b89040-9508-48c9-9847-bd17179f2179',
    apiEndpoint: 'https://api.easyplatform.app/',
    graphEndpint: 'https://graph.microsoft.com',
    adminLoginUrl: 'https://admin.easyplatform.app/',
    teamsAppUrl: 'https://teams.easycallreport.dev.easyplatform.app',
    scopes: [ 'api://teams.easycallreport.dev.easyplatform.app/53b89040-9508-48c9-9847-bd17179f2179/access_as_user' ],
    //scopes: ["User.Read", "Presence.Read.All", "User.ReadBasic.All"]
  };
  
export default config;
