import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./TeamsFxContext";
import config from "../common/config";
import { GraphEndpoint, Providers } from "@microsoft/mgt-element";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { TeamsUserCredentialAuthConfig, TeamsUserCredential } from "@microsoft/teamsfx";

const DefaultLayout = React.lazy(() => import("../layout/DefaultLayout"));

export default function App() {

  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  
const baseURL: GraphEndpoint = config.graphEndpint as GraphEndpoint;

const authConfig: TeamsUserCredentialAuthConfig = {
  clientId: config.clientId,
  initiateLoginEndpoint: config.initiateLoginEndpoint,
};

const credential = new TeamsUserCredential(authConfig);
const provider = new TeamsFxProvider(credential, config.scopes, baseURL);

Providers.globalProvider = provider;

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
                colorNeutralBackground1: "#fff",
              }
        }
        style={{ background: tokens.colorNeutralBackground1, padding: "20px 20px 10px 15px" }}
      >
        <BrowserRouter>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Routes>
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          )}
        </BrowserRouter>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}